<template>
  <div class="introb1">
    <div class="introb1__wrapper fullscreen">
      <div class="introb1__background">
        <GalleryPic :animation="'no-animation'" :imgpath="'assets/img/header/0.jpg'" class="fix--bg"/>
        <div class="introb1__overlay introb1__overlay--black"></div>
      </div>
    
      <div class="introb1__content ">
        <h1 class="introb1__h1 h1 h1--white">Über Uns</h1>
        <p class="p introb1__p p--white">
   Was als kleines Nebengewerbe begann, hat sich kontinuierlich erweitert und schließlich zu einem Vollzeit-Job entwickelt. Wir bieten umfassende Beratung zu Mängelbehebungen und dem An- und Verkauf von Fahrzeugen, und stellen sicher, dass unsere Arbeit zu einem fairen Preis angeboten wird. Unsere Werkstatt ist als Meisterbetrieb bei der Handwerkskammer registriert und verfügt über modernste technische Ausrüstung. Wir bleiben auf dem neuesten Stand der KFZ-Branche, um unseren Kunden stets qualitativ hochwertige Dienstleistungen bieten zu können.
        </p>

      </div>
    </div>
  </div>
</template>

<script>
import GalleryPic from '../gallery/GalleryPic.vue';

export default {
  components: { GalleryPic },
};
</script>

<style lang="scss"></style>
