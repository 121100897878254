<template>
  <div class="GL">
    <div class="GL__wrapper">
      <!-- <div class="gallery__item"><AppHl>Aussteller</AppHl></div> -->
      <div class="introb1__wrapper">
      <div class="introb1__content">
        <h1 class="introb1__h1 h1">LEISTUNGEN</h1>
        <h2 class="introb1__h2 h2">Wir helfen ihnen</h2>
        <p class="p introb1__p">
          Autoreparaturen sind ein wichtiger Bestandteil unserer Leistungen bei Autotechnik-gabriel Gmbh, um Probleme mit Fahrzeugen zu beheben. Unsere Experten sind qualifizierte Mechaniker und bieten sowohl einfache Wartungsarbeiten wie Ölwechsel und Reifenwechsel als auch komplexe Reparaturen von Getrieben, Bremsen oder Motoren an. Wir legen großen Wert darauf, dass jede Reparatur sicher und effektiv durchgeführt wird, um die Zufriedenheit unserer Kunden zu gewährleisten. Eine regelmäßige Wartung und schnelle Problemerkennung ist auch wichtig, um größere Schäden und teure Reparaturen zu vermeiden. Wir stehen Ihnen gerne zur Verfügung, um Ihnen bei allen Arten von Autoreparaturen zu helfen.
        </p>


      </div>
    </div>
      <div class="GL__content">



        <div
          v-for="(leistung, index) in leistungen"
          :key="index"
          class="GL__box"
        >
          <div class="GL_flexwrapper">
            <div class="GL__imgbox">
              <GalleryPic :imgpath="'assets/img/header/2.jpg'"
              :animation="'no-animation'" class="GL__img" />
              <div class="GL__overlay"></div>
              <div class="GL__txt">{{ leistung.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import service from "@/utilities/siteinformation/service.js";
// import AppButton from "@/components/ui/buttons/AppButton.vue";
// import AppHl from "@/components/ui/buttons/AppHl.vue";
import GalleryPic from "./GalleryPic";
export default {
  components: { GalleryPic },
  setup() {
    const leistungen = service;

    return { leistungen };
  },
};
</script>

<style></style>
