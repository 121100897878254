<template>
  <div class="introb1">
    <div class="introb1__wrapper">
      <div class="introb1__content">
        <h1 class="introb1__h1 h1">Autotechnik Gabriel GmbH</h1>
        <h2 class="introb1__h2 h2">Freier KFZ-Meisterbetrieb aller Marken</h2>
        <p class="p introb1__p">
          Autotechnik Gabriel GmbH ist Ihr zuverlässiger Partner für alle
        Fragen rund um Ihr Fahrzeug. Wir bieten Ihnen erstklassige
        Dienstleistungen wie Reparaturen, Wartungen und Inspektionen für alle
        Marken und Modelle an. Unsere erfahrenen Techniker arbeiten mit
        modernster Technologie und nutzen ausschließlich hochwertige
        Ersatzteile, um sicherzustellen, dass Ihr Fahrzeug stets in
        einwandfreiem Zustand ist. Kontaktieren Sie uns noch heute, um einen
        Termin zu vereinbaren und erleben Sie unseren hervorragenden Service
        selbst.
        </p>
        <div class="introb1__btn">

      
        <AppButton class="" :scrolllink="'leistungen'" :action="'email'"
          >Jetzt Termin anfragen</AppButton
        >
      </div>

      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/ui/buttons/AppButton.vue";
export default {
  components: { AppButton },
};
</script>

<style lang="scss"></style>
