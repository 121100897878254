<template>
  <div class="header"  ref="scrollContainerLets">
    <div class="header__wrapper" ref="headerWrapper">
      <div
        class="header__img"
        :style="[
          { backgroundImage: setBackGroundPath(imgpath) },
          { backgroundPosition: imgposition },
        ]"
      ></div>
      <div class="header__block">
        <!-- <div class="header__sl">
          13. Weihnachtlicher<br />
          Kunsthandwerker
        </div>
        <div class="header__hl">
          MARKT der <br />MÖGLICHKEITEN
        </div> -->
        <div class="header__logo">  <the-logo :animation="true"/></div>
      
      </div>
    </div>
  </div>
</template>

<script>

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { ref, onMounted } from "vue";


import getPathFunction from "@/utilities/composition/getPathFunction";
import TheLogo from "@/components/ui/logo/TheLogo.vue";
export default {
  components:{TheLogo},
  props: { imgpath: String, imgposition: String, motion: String, animation:String },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    const headerWrapper = ref(null);
    const header = ref(null);


    const allowScroll = ref(true);

    const scrollContainerLets = ref(null);
    const startAnimate = ref(false);

    let scrollT = null;

    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
    });

    function startAnimation() {
      let hi = scrollContainerLets.value.querySelector(".header__img");

      console.log("HI", hi)
      let els_lets = scrollContainerLets.value.querySelectorAll(
        ".header__block"
      );
      let time = { time: 0 };

      // els_0.forEach((element, index) => {
      //   let dir = index % 2 > 0 ? -1 : 1;
      //   let dist = 80 * dir;
      //   console.log(index % 2);
      //   tl.from(
      //     element,
      //     {
      //       duration: 1,
      //       y: dist,
      //       ease: "power1.inOut",
      //       yoyo: true,
      //       repeat: -1,
      //     },
      //     index * 0.1
      //   );
      // });

      tl.to(els_lets, { duration: 1, y: "46%", ease:"none" }, 1.5)
        // .to(hi, {duration: 1, opacity:0 }, 1.5)
        .to(time, { duration: 3 }, 0);

      scrollT = ScrollTrigger.create({
        // scroller: "#main__content",
        pin: false, // pin the trigger element while active
        animation: tl,
        trigger: scrollContainerLets.value,
        scrub: true,
        start: "top 100%",
        // end: "+=50%",
        // onUpdate: (self) => {
        //   console.log(self.progress.toFixed(3));
        // },
      });
      scrollT.refresh();
    }

    onMounted(() => {
      if (allowScroll.value) {

        startAnimation();
       
     
      }
    });



    return { setBackGroundPath, headerWrapper, header,scrollContainerLets, startAnimate  };
  },
};
</script>

