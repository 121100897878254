<template>
  <div class="introb1">
    <div class="introb1__wrapper fullscreen">
      <div class="introb1__background">
        <GalleryPic
          :animation="'no-animation'"
         
          class="fix--bg"
        />
        <!-- :imgpath="'assets/img/header/4.jpg'" -->
        <div class="introb1__overlay introb1__overlay--white"></div>
      </div>

  
 
      <div class="introb1__content ">
        <h1 class="introb1__h1 h1 ">KONTAKT</h1>
        <p class="p introb1__p">
          Autotechnik Gabriel befindet sich in der Mühlweg 2, in Wangen im
          Allgäu, Deutschland. Die Werkstatt liegt in einem Gewerbegebiet im
          Osten der Stadt, in der Nähe des Autobahnzubringers zur A96.
        </p>
        <NavKontaktBtns class="introb1__kontaktbtns"/>
      </div>
    </div>
  </div>
</template>

<script>
// import NavKontaktBtns from "../../../components/common/navigation/NavKontaktBtns.vue"
import contactInformation from "@/utilities/siteinformation/contactInformation";

import GalleryPic from "../gallery/GalleryPic.vue";
import NavKontaktBtns from '../navigation/NavKontaktBtns.vue';

export default {
  components: { GalleryPic, NavKontaktBtns  },

  setup() {
    const kontakt = contactInformation;

    return {
      kontakt,
    };
  },
};
</script>

<style lang="scss"></style>
