<template>
  <div class="introb1__devider"  ref="scrollContainerLets">
      <div
        class="introb1__devider--img"
        :style="[
          { backgroundImage: setBackGroundPath(imgpath) },
          { backgroundPosition: imgposition },
          { backgroundAttachment: imgattachment },
        ]"
      ></div>
  </div>
</template>

<script>

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { ref } from "vue";


import getPathFunction from "@/utilities/composition/getPathFunction";
export default {
  props: { imgpath: String, imgposition: String, motion: String, animation:String, imgattachment:String },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    const headerWrapper = ref(null);
    const header = ref(null);




    return { setBackGroundPath, headerWrapper, header,  };
  },
};
</script>

