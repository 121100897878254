<template>
  <div class="page page__home">
    <HeaderPic :imgpath="'assets/img/header/new.jpg'"   :imgposition="'50% 50%'"/>
    <IntroBlockHome class="section" />
    <Aktuelles v-if="getOverlayState" class="" />
    <IntroBlockDevider :imgpath="'assets/img/header/7.jpg'" :imgposition="'80% 50%'" :imgattachment="'fixed'"/>
    <GalleryLeistungen class="section" />
    <IntroBlockUeber class="" />
    <IntroBlockKontakt class="" />
    <IntroBlockDevider :imgpath="'assets/img/header/1.jpg'" :imgposition="'80% 50%'" :imgattachment="'fixed'"/>
    <TheFooter class="section" />
  </div>
</template>

<script>
import HeaderPic from "@/components/common/header/HeaderPic";
import IntroBlockDevider from "@/components/common/introblock/IntroBlockDevider.vue";
import IntroBlockHome from "@/components/common/introblock/IntroBlockHome.vue";
import IntroBlockUeber from "@/components/common/introblock/IntroBlockUeber.vue";
import IntroBlockKontakt from "@/components/common/introblock/IntroBlockKontakt.vue";
import GalleryLeistungen from "@/components/common/gallery/GalleryLeistungen.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";

import { useStore } from "vuex";

import Aktuelles from "../components/common/aktuelles/Aktuelles.vue";
import { computed } from 'vue';

export default {
  components: {
    HeaderPic,
    IntroBlockDevider,
    IntroBlockHome,
    IntroBlockUeber,
    IntroBlockKontakt,
    GalleryLeistungen,
    TheFooter,
    Aktuelles
},

  setup() {
    const store = useStore();
    const getOverlayState = computed(() => store.state.isOverlayOpen);
    // MENU STYLINGS:

    return { store,getOverlayState };
  },
};
</script>
<style
  lang="
    scss"
></style>
