<template>
    <div>

        <div class="notifications notifications--aktuell">
            <div class="introb1__close">
                <AppButtonCloseOverlay />
            </div>
            <div class="introb1__wrapper">
                <div class="introb1__content introb1__content">
                    <h1 class="introb1__h1 h1 introb1__h1--nomarginbottom">
                        aktuell!
                        <br>
                    </h1>
                    <p class="p introb1__p">

                        <span>
                            <b>Betriebsurlaub:

                            </b>
                            <br>
                            Wir haben von
                            23.12.2023 - 7.1.2024
                            geschlossen.
                            <br>
                            Ab dem 8.1.2024 haben wir wie gewohnt für Sie geöffnet.
                            <br>
                            Wir wünschen Ihnen frohe Weihnachten und einen schwungvollen Start ins Jahr 2024!
                        </span>
                    </p>
                    <div class="introb1__boxbutton">
                    </div>
                </div>
            </div>
            <div class="introb1__logo">
                <TheLogo />
            </div>
        </div>
    </div>
</template>

<script>

export default {

    components: {
    }

}
</script>

<style lang="scss" scoped>

.notifications--aktuell{
max-width: 100%;
}
</style>